import ResizeObserver from 'resize-observer-polyfill'
import gsap from 'gsap';

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from "../_app/cuchillo/scroll/VScroll_Item";
import PhysicsWorld from '../physics/PhysicsWorld';

class ScrollItem__University extends VScroll_Item {
  _element;
  _words;
  _wordIndex = 0;
  _shapes = [];

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._element = this.item.querySelector('.__container');
    this._words = Array.from(this.item.querySelectorAll('.__word'));

    this.selectTargets();

    this.physicsWorld = new PhysicsWorld(
      this._element,
      this._shapes
    );

    this._resize_observer = new ResizeObserver(() => {
      clearTimeout(this._timer);
      this._timer = setTimeout(() => {
        this.physicsWorld.resize();
        if (this.isShow) this.physicsWorld.init();
      }, 300);
    });
    this._resize_observer.observe(this.item);

    const callWords = time => { this.animateWords(time); };

    this.onShow = () => {
      this.physicsWorld.init();
      gsap.ticker.add(callWords);
    };

    this.onHide = () => {
      this.physicsWorld.reset();
      gsap.ticker.remove(callWords);
    };
  }

  dispose () {
    this.physicsWorld.dispose();
    this._resize_observer.disconnect();
    this._resize_observer = null;

    super.dispose();
  }

  animateWords (time) {
    const iteration = parseInt(time * 2 % this._words.length);

    if (iteration !== this._wordIndex) {
      this._words.map((word, index) => {
        if (index === iteration) word.style.display = 'block';
        else word.style.display = 'none';
      });

      this._wordIndex = iteration;
    }
  }

  selectTargets () {
    const u = this._element.querySelector('.__ushape path');
    const exclamation = this._element.querySelector('.__exclamation path');
    const semiCircle = this._element.querySelector('.__semicircle path');
    const circle = this._element.querySelector('.__circle path');
    const dot = this._element.querySelector('.__dot path');

    this._shapes.push({
      element: u,
      type: 'svg',
      opts: {
        fillStyle: '#0000F9',
        strokeStyle: '#0000F9',
        randomPos: true,
        lineWidth: 2,
        rotation: {
          min: -2,
          max: 2
        }
      }
    });
    this._shapes.push({
      element: exclamation,
      type: 'svg',
      opts: {
        fillStyle: '#ffffff',
        strokeStyle: '#000000',
        randomPos: true,
        lineWidth: 2,
        rotation: {
          min: -5,
          max: 5
        },
        index: 2
      }
    });
    this._shapes.push({
      element: semiCircle,
      type: 'svg',
      opts: {

        fillStyle: '#000000',
        strokeStyle: '#000000',
        randomPos: true,
        lineWidth: 2,
        rotation: {
          min: -10,
          max: 10
        },
        index: 1.5
      }
    });
    this._shapes.push({
      element: circle,
      type: 'svg',
      opts: {
        fillStyle: '#FFFFFF',
        strokeStyle: '#000000',
        randomPos: true,
        lineWidth: 2,
        rotate: false,
        index: 1.5
      }
    });
    this._shapes.push({
      element: dot,
      type: 'svg',
      opts: {
        fillStyle: '#0000F9',
        strokeStyle: '#0000F9',
        randomPos: true,
        lineWidth: 2,
        rotate: false,
        index: 1.5
      }
    });
  }
}

Scroll._registerClass('university', ScrollItem__University);
