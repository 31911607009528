import { Bodies } from 'matter-js';

import { CSS } from '../_app/cuchillo/utils/CSS';
import { Maths } from '../_app/cuchillo/utils/Maths';
import Physics__Item from './Physics__Item';

class Physics__Item__Dom extends Physics__Item {
  dom;

  constructor(__dom, opts = {}) {
    super(opts);

    this.dom = __dom;
    this.height = this.dom.getBoundingClientRect().height;
    this.width = this.dom.getBoundingClientRect().width;
  }

  setupBox () {
    const X = this.opts.randomPos
      ? Maths.maxminRandom(this.width / 2, this.opts.containerWidth - this.width / 2)
      : this.opts.position.x;
    const Y = this.opts.randomPos
      ? this.opts.containerHeight * Maths.maxminRandom(-1, 0) - this.height / 2
      : this.opts.position.y;

    this.box = Bodies.rectangle(
      X,
      Y,
      this.width, this.height,
      {
        restitution: .6,
        render: {
          sprite: {
            texture: this.opts.texture,
            xScale: 1,
            yScale: 1,
          }
        },
        isStatic: this.opts.isStatic
      }
    );
    if (this.opts.rotate) this.box.angle =
      Maths.toRadians(Maths.maxminRandom(this.opts.rotation.min, this.opts.rotation.max));
  }

  update () {
    this.dom.style[CSS.transform] =
      CSS.translate3D(
        this.box.position.x * 1 - (this.width / 2),
        this.box.position.y * 1 - (this.height / 2), 1)
      + `rotate(${this.box.angle}rad)`;
  }
}

export default Physics__Item__Dom;
