class Physics__Item {
  width;
  height;
  box;

  defaults = {
    fillStyle: '#ffffff',
    strokeStyle: '#000000',
    lineWidth: 1,
    randomPos: false,
    position: {
      x: 0,
      y: 0
    },
    rotate: true,
    rotation: {
      min: -5,
      max: 5
    },
    scale: 1,
    containerHeight: 0,
    containerWidth: 0,
    isStatic: false,
    height: 0,
    width: 0,
    index: 1,
    texture: null
  }

  constructor(opts = {}) {
    this.opts = {
      ...this.defaults,
      ...opts
    };
  }

  setupBox () { }

  update () { }

  // reset () { }
  // resize () { }
}

export default Physics__Item;
