import gsap, { Expo, Quad } from "gsap";
import DrawSVGPlugin from 'gsap/dist/DrawSVGPlugin';

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from "../_app/cuchillo/scroll/VScroll_Item";

gsap.registerPlugin(DrawSVGPlugin);

class ScrollItem__Underline extends VScroll_Item {
  _selector = ".__p1";
  _paths;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._paths = Array.from(this.item.querySelectorAll(this._selector));
    this._paths.map(path => gsap.set(path, { drawSVG: "0 0" }));

    this.onShow = () => {
      this._paths.map((path, index) => {
        gsap.to(path, {
          drawSVG: "0 100%",
          duration: .8,
          ease: Expo.easeIn,
          delay: .2 + (.4 * index)
        });
      });
    };

    this.onHide = () => {
      this._paths.map(path => {
        gsap.to(path, {
          drawSVG: "0 0",
          duration: .3,
          ease: Quad.easeOut,
        });
      });
    };
  }
}

Scroll._registerClass("underline", ScrollItem__Underline);
