import { isTouch } from '../_app/cuchillo/core/Basics';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import gsap, { Power2 } from "gsap";

class ScrollItem__Methodology extends VScroll_Item {
  _selectorItems = '.__tab-slider';
  _items;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._items = Array.from(this.item.querySelectorAll(this._selectorItems));

    this.onShow = () => {
      if (isTouch) {
        this._items.map((item, index) => {
          gsap.to(item, {
            x: 0,
            duration: 2.2,
            ease: Power2.easeOut,
            delay: 1.4 + (.15 * index)
          });
        });
      }
    };
  }
}

Scroll._registerClass('key-benefits', ScrollItem__Methodology);
