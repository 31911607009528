import {
  Common,
  Engine,
  Render,
  World
  // Body
} from 'matter-js';
import 'pathseg';
import polyDecomp from 'poly-decomp';
import gsap from 'gsap';

import Physics__Item__SVG from '../physics/Physics__Item__SVG';
import Physics__Item__Wall from './Physics__Item__Wall';
import Physics__Item__Dom from '../physics/Physics__Item__Dom';

Common.setDecomp(polyDecomp);

class PhysicsWorld {
  _domElements = [];
  _shapes = [];
  _items = [];
  _walls = [];
  _container;
  _initialized = false;
  WALL_SIZE = 300;
  SCREEN_SCALE = 1;
  SVG_SCALE = 1.2;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(container, items) {
    this._container = container;
    this._items = items;

    this._height = this._container.getBoundingClientRect().height;
    this._width = this._container.getBoundingClientRect().width;
    this.SCREEN_SCALE = this._width / 1440;

    this.setupWorld();
    this.setupWalls();
    this.setupShapes();

    this._call = () => this.loop();
  }

  init = () => {
    Engine.run(this._engine);
    Render.run(this._render);

    gsap.ticker.add(this._call);

    this._initialized = true;
  }

  resize () {
    this._height = this._container.getBoundingClientRect().height;
    this._width = this._container.getBoundingClientRect().width;
    this.SCREEN_SCALE = this._width / 1440;

    this.reset();
  }

  reset () {
    if (this._initialized) {
      Render.stop(this._render);
      World.clear(this._engine.world);
      Engine.clear(this._engine);

      gsap.ticker.remove(this._call);

      this._engine = null;
      this._render.canvas.remove();
      this._render.canvas = null;
      this._shapes = [];
      this._walls = [];

      this.setupWorld();
      this.setupWalls();
      this.setupShapes();
    }
  }

  dispose () {
    if (this._initialized) {
      Render.stop(this._render);
      World.clear(this._engine.world);
      Engine.clear(this._engine);

      this._engine = null;
      this._render.canvas.remove();
      this._render.canvas = null;
      this._shapes = [];
      this._walls = [];
    }
  }

  loop () {
    this._shapes.map(shape => {
      shape.update();
    });
  }

  setupWorld = () => {
    this._engine = Engine.create();
    this._engine.world.gravity = 1.6;

    this._render = Render.create({
      element: this._container,
      engine: this._engine,
      options: {
        height: this._height,
        width: this._width,
        wireframes: false,
        background: '#ffffff',
        pixelRatio: window.devicePixelRatio
      }
    });
  }

  setupWalls = () => {
    // Right
    const right = new Physics__Item__Wall({
      strokeStyle: '#ffffff',
      containerHeight: this._height,
      containerWidth: this._width,
      lineWidth: 1,
      rotate: false,
      position: {
        x: this._width + this.WALL_SIZE / 2,
        y: this._height * .5
      },
      width: this.WALL_SIZE,
      height: this._height * 5,
      isStatic: true
    });
    right.setupBox();
    this._walls.push(right);
    World.add(this._engine.world, right.box);

    // Left
    const left = new Physics__Item__Wall({
      strokeStyle: '#ffffff',
      containerHeight: this._height,
      containerWidth: this._width,
      lineWidth: 1,
      rotate: false,
      position: {
        x: - this.WALL_SIZE / 2,
        y: this._height * .5
      },
      width: this.WALL_SIZE,
      height: this._height * 5,
      isStatic: true
    });
    left.setupBox();
    this._walls.push(left);
    World.add(this._engine.world, left.box);

    // Bottom
    const bottom = new Physics__Item__Wall({
      strokeStyle: '#ffffff',
      containerHeight: this._height,
      containerWidth: this._width,
      lineWidth: 1,
      rotate: false,
      position: {
        x: this._width * .5,
        y: this._height + this.WALL_SIZE / 2 - 1
      },
      width: this._width,
      height: this.WALL_SIZE,
      isStatic: true
    });
    bottom.setupBox();
    this._walls.push(bottom);
    World.add(this._engine.world, bottom.box);
  }

  setupShapes = () => {
    this._items.map(item => {
      let shape;

      if (item.type === 'dom') {
        shape = new Physics__Item__Dom(item.element, {
          ...item.opts,
          containerHeight: this._height,
          containerWidth: this._width,
          scale: this.SCREEN_SCALE
        });
      } else if (item.type === 'svg') {
        shape = new Physics__Item__SVG(item.element, {
          ...item.opts,
          containerHeight: this._height,
          containerWidth: this._width,
          scale: this.SCREEN_SCALE * this.SVG_SCALE
        });
      }

      this._shapes.push(shape);

      shape.setupBox();
      World.add(this._engine.world, shape.box);
    });
  }
}

export default PhysicsWorld;
