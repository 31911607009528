import gsap, { Power3 } from "gsap";

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from "../_app/cuchillo/scroll/VScroll_Item";

export class ScrollItem__Section extends VScroll_Item {
  _selector = ".__compatibility-table";
  _option = ".__option";
  _result = ".__result";
  _tables;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._tables = Array.from(this.item.querySelectorAll(this._selector));
    this._tables.map(table => {
      const counter = { val: 0 };
      const options = Array.from(table.querySelectorAll(this._option));
      const result = table.querySelector(this._result);

      this.onClick = e => {
        const opt = e.currentTarget;

        if (opt.classList.contains('active')) opt.classList.remove('active');
        else opt.classList.add('active');

        const percent = options.filter(item => item.classList.contains('active')).length / options.length * 100;
        gsap.to(counter, {
          val: percent.toFixed(0),
          duration: .6,
          delay: .1,
          onUpdate: () => {
            result.innerHTML = counter.val.toFixed(0);
          },
          ease: Power3.easeOut
        });
      };

      options.map(opt => {
        opt.addEventListener('click', this.onClick);
      });
    });
  }

  dispose () {
    this._tables.map(table => {
      const options = Array.from(table.querySelectorAll(this._option));

      options.map(opt => {
        opt.removeEventListener('click', this.onClick);
      });
    });

    super.dispose();
  }
}

Scroll._registerClass('compatibility', ScrollItem__Section);
