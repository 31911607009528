import gsap, { Power2, Power4 } from "gsap";
import { SplitText } from "gsap/SplitText";

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import Page from '../_app/cuchillo/pages/Page';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import { ControllerWindow } from '../_app/cuchillo/windows/ControllerWindow';
//import { Videos } from '../_app/cuchillo/components/Videos';
//import { Acordions } from '../_app/cuchillo/components/Acordions';
import { isMobile } from '../_app/cuchillo/core/Basics';
import { GetBy, C } from "../_app/cuchillo/core/Element";
//import Forms from '../_app/cuchillo/forms/FormValidator';
import Wrap from '../layout/Wrap';
import CircleScrollbar from '../scroll/CircleScrollbar';

gsap.registerPlugin(SplitText);

export default class Default extends Page {
  id;
  isWrapAutoRemove = true;
  idTimer;
  loaderLayer = GetBy.id('Loader');
  _billboard;
  _titleSelector = '.__title p';
  _titles;
  _iconSelector = '.__icon';
  _icon;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor() {
    super();
    this.id = "__" + new Date().getTime();

    this.setupBlocks();
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================

  setupBlocks () {
    const change = () => {
      C.forEach(".__grad", (e) => {
        e.style.setProperty('--grad', `${Math.round(Math.random())}`);
      });
    }

    change();
    //this.idTimer = setInterval(change, 500)
  }

  //SHOW
  _show () {
    if (!this.isFirstTime) super._show();
    else {
      const logo = this.loaderLayer.querySelector('svg');

      Wrap.directShow();

      gsap.to(logo, {
        y: 0,
        duration: 1.2,
        ease: Power4.easeOut
      });
      gsap.to(logo, {
        y: '-100%',
        duration: .4,
        ease: Power2.easeOut,
        delay: 2.2,
      });
      gsap.to(this.loaderLayer, {
        opacity: 0,
        duration: .4,
        ease: Power2.easeOut,
        delay: 2.5,
        onComplete: () => {
          this.loaderLayer.classList.add('hidden');
          this.afterShow();
        }
      });
    }
  }

  beforeShow () {
    Scroll.init(Scroll.AXIS_Y, { smooth: !isMobile, multiplicator: 1 });
    Scroll.setScrollbar(new CircleScrollbar());
    Scroll.addAll();
    Scroll.resize();
    Scroll.resize();
    Scroll.start();

    this.setBillboardAnimations();
  }

  show__effect (__call) {
    Wrap.show(() => { this.afterShow() });
  }

  afterShow () {
    this.animateBillboard();
    Scroll.resize();
    Scroll.show();

    if (window.location.href.indexOf('#') > -1) {
      Scroll.gotoAnchor(window.location.href.split('#')[1]);
    }

    super.afterShow();
  }

  setBillboardAnimations () {
    this._billboard = GetBy.id('billboard');
    this._icon = this._billboard.querySelector(this._iconSelector);

    const lines = this._billboard.querySelectorAll(this._titleSelector);
    this._titles = new SplitText(lines, { type: 'lines' }).lines;

    this._titles.map(title => {
      const wrapper = document.createElement('div');
      wrapper.classList.add('line');
      title.parentNode.insertBefore(wrapper, title);
      wrapper.appendChild(title);

      gsap.set(title, { y: '110%' });
    });
    gsap.set(this._icon, {
      opacity: 0,
      y: '3%'
    });
  }

  animateBillboard () {
    this._titles.map((title, index) => {
      gsap.to(title, {
        y: 0,
        duration: 1.4,
        delay: .08 * index,
        ease: Power4.easeOut
      });
    });
    gsap.to(this._icon, {
      opacity: 1,
      y: 0,
      delay: .5,
      duration: 2.1,
      ease: Power4.easeOut
    });
  }

  //HIDE
  beforeHide () {
    ControllerWindow.hideAll();
  }

  hide__effect () {
    //Sidemenu.hide();
    // Phrases.show(() => {this.afterHide();});
    clearInterval(this.idTimer);
    Wrap.hide(() => { this.afterHide(); });
  }

  afterHide () {
    // TweenLite.killTweensOf(this.container);
    Scroll.hide();
    Scroll.dispose();
    super.afterHide();
  }

  //RESIZE
  resize () {
    super.resize();
  }

  //LOOP
  loop () {
    if (this._isActive) {
      super.loop();
    }
  }
}

ControllerPage._addPage("default", Default)
