import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { SliderScroll } from '../_app/cuchillo/components/SliderScroll';
import gsap, { Power4 } from "gsap";

class ScrollItem__Slider extends VScroll_Item {
  _selector = ".__title";
  _titles;
  _slider;
  _progressBar;
  _call;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._titles = Array.from(this.item.querySelectorAll(this._selector));

    this._slider = new SliderScroll(__link,
      {
        onDragStart: () => {
          __link.classList.add('dragging');
        },
        onDragEnd: () => {
          __link.classList.remove('dragging');
        },
        interaction: true
      });

    this._call = () => this.loop();
    this.onShow = () => {
      gsap.ticker.add(this._call);

      this._titles.map(title => {
        gsap.to(title, {
          y: 0,
          duration: 1.2,
          ease: Power4.easeOut,
          delay: .4
        });
      });
    };

    this.onHide = () => {
      gsap.ticker.remove(this._call);
    };
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================

  show () {
    super.show();
  }

  hide () {
    super.hide();
  }

  loop () {
    this._slider.loop();
  }

  dispose () {
    gsap.ticker.remove(this._call);
    this._slider.dispose();
    super.dispose();
  }

  resize (__w, __h) {
    super.resize(__w, __h)
    this._slider.resize();
  }
}

Scroll._registerClass('slider', ScrollItem__Slider);