import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import gsap, { Power2, Power4 } from "gsap";

class ScrollItem__Counter extends VScroll_Item {
  _selectorCounter = '.__counter';
  _counters;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._counters = Array.from(this.item.querySelectorAll(this._selectorCounter));

    this.onShow = () => {
      this._counters.map((item, index) => {
        const counter = { val: 0 };
        const number = parseFloat(item.dataset.counter.replace(',', '.'));
        const hasDecimals = number % 1 !== 0;

        gsap.to(counter, {
          val: number,
          duration: 1.5,
          delay: .6,
          onUpdate: () => {
            if (hasDecimals) item.innerHTML = counter.val.toFixed(2).replace('.', ',');
            else item.innerHTML = counter.val.toFixed(0);
          },
          ease: Power2.easeOut,
          delay: .2 * index
        });
      });
    };
  }
}

Scroll._registerClass('counter', ScrollItem__Counter);
