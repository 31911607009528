import _Header from '../_app/cuchillo/layout/Header';
import { Power3 } from "gsap";

const NAV_OPEN = '__nav-open';
const TOGGLE = '__toggle';

export default class Header extends _Header {
  static enabledScroll = true;

  static options = {
    show: {
      duration: .3,
      delay: 0,
      ease: Power3.easeOut
    },
    hide: {
      duration: .3,
      delay: 0,
      ease: Power3.easeIn
    }
  }

  static hideNav () {
    this.container.classList.remove(NAV_OPEN);
  }

  static showNav () {
    this.container.classList.add(NAV_OPEN);
  }

  static enableScroll () {
    this.enabledScroll = true;
  }

  static disableScroll () {
    this.enabledScroll = false;
  }

  static toggleMode () {
    if (this.container.classList.contains(TOGGLE)) this.container.classList.remove(TOGGLE);
    else this.container.classList.add(TOGGLE);
  }

  static loop () {
    if (this.enabledScroll) super.loop();
  }
};
