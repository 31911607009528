import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { SliderScroll } from '../_app/cuchillo/components/SliderScroll';
import gsap from "gsap";

class ScrollItem__SliderFull extends VScroll_Item {
  _slider;
  _progressBar;
  _call;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._slider = new SliderScroll(__link,
      {
        interaction: false,
        hasScrollbar: true
      });

    this._call = () => this.loop();
    this.onShow = () => {
      gsap.ticker.add(this._call);
    };

    this.onHide = () => {
      gsap.ticker.remove(this._call);
    };

    this.onMove = (__position) => {
      this._slider.progress = 1 - this.progressInside;
    }
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  show () {
    super.show();
  }

  hide () {
    super.hide();
  }

  loop () {
    this._slider.loop();
  }

  dispose () {
    gsap.ticker.remove(this._call);
    this._slider.dispose();
    super.dispose();
  }

  resize (__w, __h) {
    super.resize(__w, __h)
    this._slider.resize();
  }
}

Scroll._registerClass('slider-full', ScrollItem__SliderFull);
