import gsap, { Power1, Power4 } from "gsap";

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from "../_app/cuchillo/scroll/VScroll_Item";
import { Interaction } from '../_app/cuchillo/core/Interaction';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { CSS } from '../_app/cuchillo/utils/CSS';
import { Maths } from '../_app/cuchillo/utils/Maths';

class ScrollItem__Pangea extends VScroll_Item {
  _selectorTitle = ".__title";
  _selectorCities = ".__city";
  _selectorItems = ".__hover-item";
  _selectorSlider = "human-pangea";
  _titles;
  _cities;
  _sliderX = 0;
  _sliderY = 0;
  _displaceY = 0;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._titles = Array.from(this.item.querySelectorAll(this._selectorTitle));
    this._hoverItems = Array.from(this.item.querySelectorAll(this._selectorItems));
    this._slider = document.getElementById(this._selectorSlider);
    this._cities = Array.from(this._slider.querySelectorAll(this._selectorCities));

    this.onMouseEnter = e => {
      const target = e.currentTarget.getAttribute('data-target');
      const city = this._cities.find(c => c.id === target);
      e.currentTarget.classList.add('active');
      city.classList.add('visible');
    };
    this.onMouseLeave = e => {
      const target = e.currentTarget.getAttribute('data-target');
      const city = this._cities.find(c => c.id === target);
      e.currentTarget.classList.remove('active');
      city.classList.remove('visible');
    };

    this._hoverItems.map(item => {
      item.addEventListener('mouseenter', this.onMouseEnter);
      item.addEventListener('mouseleave', this.onMouseLeave);
    });

    const loop = () => { this.loop(); }

    this.onShow = () => {
      gsap.ticker.add(loop);

      this._titles.map(title => {
        gsap.to(title, {
          y: 0,
          duration: 1.2,
          ease: Power4.easeOut
        });
      });
    };

    this.onHide = () => {
      gsap.ticker.remove(loop);
    };
  }

  loop () {
    const toX = Interaction.positions.mouse.x + Metrics.WIDTH * .01;
    const toY = Interaction.positions.mouse.y + Metrics.WIDTH * .01;

    let xProgress = Maths.precission(toX / Metrics.WIDTH, 2);
    const displaceX = Maths.normalize(-.5, .5, xProgress);

    this._sliderX = Maths.lerp(toX, this._sliderX, .9);
    this._sliderY = Maths.lerp(toY, this._sliderY, .9);

    this._slider.style.setProperty('--displaceX', `${displaceX * 100 / 3}%`);
    this._slider.style[CSS.transform] = CSS.translate3D(this._sliderX, this._sliderY, 1);
  }

  dispose () {
    this._hoverItems.map(item => {
      item.removeEventListener('mouseenter', this.onMouseEnter);
      item.removeEventListener('mouseleave', this.onMouseLeave);
    });

    super.dispose();
  }
}

Scroll._registerClass('pangea', ScrollItem__Pangea);
