import gsap, { Power2, Power4 } from "gsap";

import { GetBy } from "../_app/cuchillo/core/Element";
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Header from '../layout/Header';
import Default from './Default';

export default class Program extends Default {
  _arrowSelector = '.__arrow';
  _arrows;
  _subtitleSelector = '.__subtitle';
  _subtitle;
  _backgroundSelector = '.__background';
  _background;
  _titleSelector = '.__title';

  beforeShow () {
    super.beforeShow();
    Header.toggleMode();
  }

  afterShow () {
    super.afterShow();
  }

  afterHide () {
    super.afterHide();
    Header.toggleMode();
  }

  setBillboardAnimations () {
    this._billboard = GetBy.id('program-billboard');
    this._titles = Array.from(this._billboard.querySelectorAll(this._titleSelector));
    this._arrows = Array.from(this._billboard.querySelectorAll(this._arrowSelector));
    this._subtitle = this._billboard.querySelector(this._subtitleSelector);
    this._background = this._billboard.querySelector(this._backgroundSelector);

    gsap.set(this._background, {
      opacity: 0,
      scaleX: 1.1,
      scaleY: 1.1
    });
    this._titles.map(title => {
      gsap.set(title, { y: '110%' });
    });
    gsap.set(this._subtitle, { opacity: 0 });
    this._arrows.map(arrow => {
      gsap.set(arrow, { x: '-100%' });
    });
  }

  animateBillboard () {
    gsap.to(this._background, {
      opacity: 1,
      scaleX: 1,
      scaleY: 1,
      duration: 1.5,
      delay: .2,
      ease: Power4.easeOut
    });
    this._titles.map((title, index) => {
      gsap.to(title, {
        y: 0,
        duration: 1.4,
        delay: .4 + (.08 * index),
        ease: Power4.easeOut
      });
    });
    this._arrows.map(arrow => {
      gsap.to(arrow, {
        x: 0,
        duration: 1,
        delay: .7,
        ease: Power4.easeOut
      });
    });
    gsap.to(this._subtitle, {
      opacity: 1,
      duration: 1,
      delay: .7,
      ease: Power2.easeOut
    });
  }
}

ControllerPage._addPage("program", Program);
