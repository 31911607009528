import gsap, { Power2 } from "gsap";

import { Metrics } from '../_app/cuchillo/core/Metrics';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';

class ScrollItem__Methodology extends VScroll_Item {
  _selectorItems = '.__tab-slider';
  _items;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._items = Array.from(this.item.querySelectorAll(this._selectorItems));

    this.onShow = () => {
      console.log('SHOW!');

      this._items.map((item, index) => {
        const child = item.querySelector('span');

        gsap.to(item, {
          x: 0,
          duration: 2.5,
          ease: Power2.easeOut,
          delay: (.1 * index),
          onComplete: () => {
            item.classList.add('animated');
          }
        });

        gsap.to(child, {
          left: 0,
          duration: 2.5,
          ease: Power2.easeOut,
          delay: .2 + (.1 * index)
        });
      });
    };
  }

  resize (w, h) {
    super.resize(w, h);

    this.opts.offsetShow = Metrics.HEIGHT * .25;
  }
}

Scroll._registerClass('methodology', ScrollItem__Methodology);
