import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';

export class ScrollItem__Section extends VScroll_Item {
  _selector = '.__text';
  _words = [
    'travel', 'discover', 'be aware', 'learn', 'act local', 'grow', 'be human'
  ];
  _target;
  _index = 0;
  _intervalTime = .5;
  _idTimer = 0;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._target = this.item.querySelector(this._selector);

    this.onShow = () => {
      this.animateWord();
      this._idTimer = setInterval(() => { this.animateWord() }, this._intervalTime * 1000);
    };
    this.onHide = () => {
      clearInterval(this._idTimer);
      this._initialized = false;
    }
  }

  animateWord () {
    this._index = this._index + 1 < this._words.length ? this._index + 1 : 0;

    this._target.innerHTML = this._words[this._index];
  }

  dispose () {
    clearInterval(this._idTimer);
    super.dispose();
  }
}

Scroll._registerClass('billboard', ScrollItem__Section);
