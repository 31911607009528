import {
  Vertices,
  Bodies,
  Svg,
} from 'matter-js';

import { Maths } from '../_app/cuchillo/utils/Maths';
import Physics__Item from './Physics__Item';

class Physics__Item__SVG extends Physics__Item {
  dom;

  constructor(__dom, opts = {}) {
    super(opts);

    this.dom = __dom;
    this.height = this.dom.getBoundingClientRect().height;
    this.width = this.dom.getBoundingClientRect().width;
  }

  setupBox () {
    const X = this.opts.randomPos
      ? Maths.maxminRandom(this.width / 2, this.opts.containerWidth - this.width / 2)
      : this.opts.position.x;
    const Y = this.opts.randomPos
      ? this.opts.containerHeight * Maths.maxminRandom(this.opts.index * -1 - 1, this.opts.index * -1) - this.height / 2
      : this.opts.position.y;

    this.box = Bodies.fromVertices(
      X,
      Y,
      Vertices.scale(Svg.pathToVertices(this.dom, 5), this.opts.scale, this.opts.scale),
      {
        restitution: .6,
        render: {
          fillStyle: this.opts.fillStyle,
          strokeStyle: this.opts.strokeStyle,
          lineWidth: this.opts.lineWidth
        }
      }, true);
    if (this.opts.rotate) this.box.angle =
      Maths.toRadians(Maths.maxminRandom(this.opts.rotation.min, this.opts.rotation.max));
  }
}

export default Physics__Item__SVG;
