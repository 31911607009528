import { gsap, Power0, Power2, Power3 } from "gsap";

import { isTouch } from '../_app/cuchillo/core/Basics';
import Win from '../_app/cuchillo/windows/Window';
import VScroll from '../_app/cuchillo/scroll/VScroll';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { GetBy } from '../_app/cuchillo/core/Element';

import Header from '../layout/Header';

class Sidemenu extends Win {
  options = {
    container: this.container,
    isMain: false
  };

  constructor(__container) {
    super(__container, "sidemenu");

    this.directHide();

    this._header = GetBy.id('Header');
    this._navItems = Array.from(GetBy.selector('#Sidemenu .list .link span'));
    this._navSubItems = Array.from(GetBy.selector('#Sidemenu .list .link-arrow'));
    this._contact = Array.from(GetBy.selector('#Sidemenu .contact .link'));
    this._content = Array.from(GetBy.selector('#Sidemenu .content'));

    if (!isTouch) {
      this.engine = new VScroll(this.options);
      this.engine.addAll('[scroll-sidemenu-item]');
      this.engine.resize();

      this._call = () => this.loop();
    }
  }

  show__effect (__d = 0) {
    gsap.set(this.container, { alpha: 0 });
    gsap.set(this._content, { alpha: 1 });
    gsap.set(this._contact, { alpha: 0 });
    gsap.set(this._navItems, { y: '-100%' });
    gsap.set(this._navSubItems, { alpha: 0 });

    gsap.to(this.container, {
      alpha: 1,
      duration: .3,
      ease: Power0.easeOut
    });

    this._navItems.map((item, i) => {
      gsap.to(item, {
        y: 0,
        duration: .9,
        delay: .3 + (.05 * i),
        ease: Power3.easeOut
      });
    });

    this._navSubItems.map((item, i) => {
      item.classList.remove('--active');

      gsap.to(item, {
        opacity: 1,
        duration: .5,
        delay: .5 + (.05 * i),
        ease: Power3.easeOut,
        onComplete: () => {
          item.classList.add('--active');
          item.removeAttribute('style');
        }
      });
    });

    gsap.to(this._contact, {
      alpha: 1,
      duration: .3,
      delay: .3,
      ease: Power0.easeIn
    });
  }

  hide__effect (__d = 0) {
    gsap.to(this._content, {
      alpha: 0,
      duration: .3,
      ease: Power3.easeOut
    });

    gsap.to(this.container, {
      alpha: 0,
      delay: .1,
      duration: .3,
      ease: Power2.easeOut,
      onComplete: () => { this.afterHide(); }
    });
  }

  actionButtonToggle (item) {
    super.actionButtonToggle(item);
  }

  show () {
    Header.showNav();
    super.show();

    if (this.engine) {
      this.enableScroll();
    }
  }

  hide () {
    Header.hideNav();
    super.hide();

    if (this.engine) {
      this.disableScroll();
    }
  }

  directHide () {
    gsap.set(this.container, { alpha: 0 });
    super.directHide();
    gsap.ticker.remove(this._call);
  }

  enableScroll () {
    if (!this.engine.enabled) this.engine.enabled = true;
    gsap.ticker.add(this._call);
    Scroll.stop();
    Header.disableScroll();
  }

  disableScroll () {
    if (this.engine.enabled) this.engine.enabled = false;
    gsap.ticker.remove(this._call);
    Scroll.start();
    Header.enableScroll();
  }

  loop () {
    this.engine.loop();
  }

  resize () {
    super.resize();
    if (this.engine) {
      this.engine.resize();
    }
  }
}

export const WinSidemenu = new Sidemenu(GetBy.id('Sidemenu'));
