import gsap, { Power4 } from "gsap";

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from "../_app/cuchillo/scroll/VScroll_Item";

export class ScrollItem__Section extends VScroll_Item {
  _selector = ".__title";
  _titles;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._titles = Array.from(this.item.querySelectorAll(this._selector));

    this.onShow = () => {
      this._titles.map(title => {
        gsap.to(title, {
          y: 0,
          duration: 1.2,
          ease: Power4.easeOut
        });
      });
    };
  }
}

Scroll._registerClass("section", ScrollItem__Section);
