import gsap, { Power2 } from "gsap";

import { GetBy } from '../_app/cuchillo/core/Element';
import Scrollbar from '../_app/cuchillo/scroll/Scrollbar';

export default class CircleScrollbar extends Scrollbar {
  container = null;
  circle = null;

  progress = 0;

  constructor(__container = GetBy.id("Scrollbar")) {
    super(__container);

    this.container = __container;
    this.circle = GetBy.class("circle", this.container)[0];

    this.setup();
  }

  setup () {
    gsap.to(this.circle, {
      duration: .3,
      drawSVG: `0% ${this.progress * 100}%`,
      ease: Power2.easeInOut
    });
  }

  drag () { }

  check () { }

  update (__progress) {
    this.progress = __progress;
    gsap.set(this.circle, { drawSVG: `0% ${__progress * 100}%` });
  }

  end () {
    this.progress = 0;
    gsap.to(this.circle, {
      duration: .3,
      drawSVG: '0% 0%',
      ease: Power2.easeInOut
    });
  }

  resize () { }

  dispose () { }
}
