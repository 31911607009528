import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import gsap, { Power2 } from 'gsap';

class ScrollItem__DreamJob extends VScroll_Item {
  _selectorItem = '.__counterItem';
  _selectorCounter = '.__counter';
  _counters;
  _index = -1;
  _transitionTime = 1;
  _intervalTime = 2;
  _idTimer = 0;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._counters = Array.from(this.item.querySelectorAll(this._selectorItem));

    this.onShow = () => {
      this.initCounter();
    };
    this.onHide = () => {
      clearTimeout(this._idTimer);
    }
  }

  initCounter () {
    const oldCounter = this._index > -1 ? this._counters[this._index] : null;
    this._index = this._index + 1 < this._counters.length ? this._index + 1 : 0;
    const counter = this._counters[this._index];

    if (oldCounter) oldCounter.classList.remove('active');
    counter.classList.add('active');

    this.animateCouter(counter.querySelector(this._selectorCounter));

    this._idTimer = setTimeout(() => { this.initCounter() }, this._intervalTime * 1000);
  }

  animateCouter (item) {
    const counter = { val: 0 };
    const number = parseFloat(item.dataset.counter.replace(',', '.'));
    const hasDecimals = number % 1 !== 0;

    gsap.to(counter, {
      val: number,
      duration: this._transitionTime,
      onUpdate: () => {
        if (hasDecimals) item.innerHTML = counter.val.toFixed(2).replace('.', ',');
        else item.innerHTML = counter.val.toFixed(0);
      },
      ease: Power2.easeOut
    });
  }

  dispose () {
    clearTimeout(this._idTimer);
    super.dispose();
  }
}

Scroll._registerClass('dream-job', ScrollItem__DreamJob);
