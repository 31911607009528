import { Bodies } from 'matter-js';

import { Maths } from '../_app/cuchillo/utils/Maths';
import Physics__Item from './Physics__Item';

class Physics__Item__Wall extends Physics__Item {
  constructor(opts = {}) {
    super(opts);

    this.height = this.opts.height;
    this.width = this.opts.width;
  }

  setupBox () {
    const X = this.opts.randomPos
      ? Maths.maxminRandom(this.width / 2, this.opts.containerWidth - this.width / 2)
      : this.opts.position.x;
    const Y = this.opts.randomPos
      ? this.opts.containerHeight * Maths.maxminRandom(-1, 0) - this.height / 2
      : this.opts.position.y;

    this.box = Bodies.rectangle(
      X,
      Y,
      this.width, this.height,
      {
        render: {
          fillStyle: this.opts.fillStyle,
          strokeStyle: this.opts.strokeStyle,
          lineWidth: this.opts.lineWidth
        },
        isStatic: this.opts.isStatic
      }
    );
    if (this.opts.rotate) this.box.angle =
      Maths.toRadians(Maths.maxminRandom(this.opts.rotation.min, this.opts.rotation.max));
  }
}

export default Physics__Item__Wall;
